<template>
  <div v-if="loaded && slide" class="service-configuration-slide-container">
    <slide-heading v-if="showDomainTransferHeading" heading="Domain Status" />
    <slide-heading v-else :html-heading="slide.heading" />

    <div v-if="slide.image_urls.length && slide.description">
      <slide-image-and-description :slide="slide" />
    </div>

    <div v-else-if="showSlideDescription">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="slideDescription" />
        <!--eslint-enable-->
      </div>
    </div>

    <div v-else-if="slide.image_urls.length">
      <div class="image-vert">
        <img
          :src="imageUrl"
          :alt="slide.image_alt_text"
        >
      </div>
    </div>

    <domain-service-configuration
      v-if="serviceType === serviceTypes.domain"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />

    <hosting-service-configuration
      v-else-if="serviceType === serviceTypes.hosting"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />

    <ssl-service-configuration
      v-else-if="serviceType === serviceTypes.ssl"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />

    <div v-else>
      <p>
        Unable to load Service
      </p>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <ct-centered-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'ServiceConfigurationSlide',
  components: {
    CtCenteredSpinner:           () => import('@/components/shared/CtCenteredSpinner'),
    DomainServiceConfiguration:  () => import('./services/DomainServiceConfiguration'),
    HostingServiceConfiguration: () => import('./services/HostingServiceConfiguration'),
    SlideImageAndDescription:    () => import('@/components/StagelineV2/slides/SlideImageAndDescription'),
    SlideHeading:                () => import('@/components/StagelineV2/shared/SlideHeading'),
    SslServiceConfiguration:     () => import('./services/SSLServiceConfiguration'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
      serviceTypes: {
        ssl: "domain-ssl",
        domain: "business-domain",
        hosting: "business-website-hosting",
      },
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
    ...mapGetters('domains', [
      'domain',
      'hostingTransferInfo',
      'standaloneHostingInfo',
      'activeDomainTransferRequest',
    ]),
    ...mapGetters('vouchers', [
      'unRedeemedVoucherByProductCategoryCompanyId',
    ]),
    showDomainTransferHeading(){
      return this.serviceType === this.serviceTypes.domain && this.activeDomainTransferRequest
    },
    showSlideDescription(){
      return this.slideDescription && !this.hostingTransferInfo && !this.activeDomainTransferRequest && !this.standaloneHostingInfo
    },
    unredeemedDomainVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-domain', this.company.id)
    },
    unredeemedHostingOrEmailVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-email', this.company.id) ||
        !!this.unRedeemedVoucherByProductCategoryCompanyId('business-website-hosting', this.company.id)
    },
    slideDescription() {
      if (this.serviceType === this.serviceTypes.domain && !this.unredeemedDomainVoucher && this.unredeemedHostingOrEmailVoucher) {
        return "Click below to use your domain to setup hosting."
      }
      return DOMPurify.sanitize(this.slide.description)
    },
    imageUrl() {
      return this.slide.image_urls[0]
    },
    serviceType() {
      return this.slide.layout_sub_type
    },
  },
  mounted() {
    this.loaded = true
  },
}
</script>
<style scoped lang="scss">
</style>
